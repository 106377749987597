/**
 * Follow the example for GRAPH_CMS_ENV
 *
 * Values surrounded by double underscores will be replaced at runtime with the
 * value set on the host's environment variable.
 */

window.envConstants = {
  GRAPH_CMS_ENV: __GRAPH_CMS_ENV__
};
